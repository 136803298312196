<template>
  <v-menu
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-content-click="false"
    :nudge-width="nudgeWidth"
    :top="top"
    offset-x
  >
    <!-- Popover Menu activator -->
    <template v-slot:activator="{ on, attrs }">
      <!-- Handle Activator Button -->
      <v-btn
        v-if="isActivatorButton"
        text
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :color="color"
      >
        <!-- icon -->
        <v-icon v-show="hasSlot('menuIconName')">
          <slot name="menuIconName"></slot>
        </v-icon>

        <!-- default -->
        <slot name="default"></slot>
      </v-btn>

      <!-- Handle activator Menu Item -->
      <v-list-item v-else v-on="on" v-bind="attrs" :disabled="disabled">
        <!-- icon -->
        <v-list-item-icon
          v-show="hasSlot('menuIcon') || hasSlot('menuIconName')"
        >
          <slot name="menuIcon">
            <v-icon :color="menuItemColorClass" :disabled="disabled">
              <slot name="menuIconName"></slot>
            </v-icon>
          </slot>
        </v-list-item-icon>

        <!-- content -->
        <v-list-item-content>
          <!-- title -->
          <v-list-item-title>
            <slot name="default"></slot>
          </v-list-item-title>

          <!-- subtitle -->
          <v-list-item-subtitle v-show="hasSlot('menuSubtitle')">
            <slot name="menuSubtitle"></slot>
          </v-list-item-subtitle>
        </v-list-item-content>

        <!-- action -->
        <v-list-item-action v-show="hasSlot('menuAction')">
          <slot name="menuAction"></slot>
        </v-list-item-action>
      </v-list-item>
    </template>

    <!-- Context of Popover Menu -->
    <layout-dialog>
      <template v-slot:title>
        <!-- Popover Title -->
        <slot name="popoverTitle">Popover Menu Title</slot>
      </template>

      <!-- Popover Icon Name-->
      <template v-slot:iconName v-show="hasSlot('popoverIconName')">
        <slot name="popoverIconName"></slot>
      </template>

      <!-- Popover Dialog Cancel -->
      <template v-slot:iconCrossName v-show="hasSlot('iconCrossName')">
        <slot name="iconCrossName"></slot>
      </template>

      <!-- Popover Dialog subtitle -->
      <template v-slot:subtitle v-show="hasSlot('subtitle')">
        <slot name="subtitle"></slot>
      </template>

      <!-- Popover Context -->
      <template v-slot:default>
        <!-- Provide default Error display -->
        <slot name="errorDisplay">
          <base-alert v-model="hasError" :dismissible="false" :type="alertType">
            <span v-html="error"></span>
          </base-alert>
        </slot>

        <!-- popover Context -->
        <slot name="popoverContext"></slot>
      </template>

      <!-- Popover Actions -->
      <template v-slot:actions>
        <slot name="popoverActions"></slot>
      </template>
    </layout-dialog>
  </v-menu>
</template>

<script>
// utils
import { isEmpty } from "@/utils";

// design
import { alertType } from "@/design/components/alert/alertModel";

// mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "LayoutPopoverMenu",
  inheritAttrs: false,
  mixins: [menuItemSettingsMixin, slottableMixin],
  components: {
    LayoutDialog: () =>
      import("@/components/shared/core/layout/dialog/LayoutDialog"),
    BaseAlert: () => import("@/components/shared/base/BaseAlert")
  },
  data() {
    return {
      alertType: alertType.error
    };
  },
  props: {
    nudgeWidth: {
      type: Number,
      default: 250
    },
    top: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: undefined
    },
    isActivatorButton: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: undefined
    }
  },
  computed: {
    hasError() {
      return !isEmpty(this.error);
    }
  }
};
</script>
